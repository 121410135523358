<template>
  <v-container fluid>
    <v-card class="px-md-6" flat>
      <v-card-title class="pa-2 lightgray">
        <v-text-field
          style="max-width: 300px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <DialogClienteObs
          v-if="dialogClienteObs"
          :dialogClienteObs.sync="dialogClienteObs"
          :edit="edit"
          :item="cliente_observacao"
          @fetch-cliente-obs="getClientesObs"
        />
        <v-btn
          @click="addClienteObs"
          color="button_1"
          class="white--text mt-6 mt-sm-0 mr-2"
          small
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-timeline
          v-for="(observacao, i) in cliente_observacoes"
          :key="i"
          class="pa-3"
          :items="cliente_observacoes"
        >
          <v-timeline-item small fill-dot color="secondary">
            <template v-slot:opposite class="align-center">
              <v-tooltip top>
                <template
                  v-slot:activator="{ on, attrs }"
                  class="d-none d-sm-flex"
                >
                  <span v-bind="attrs" v-on="on">
                    <p class="text-h5 mb-0 font-weight-bold text-center">
                      {{ observacao.created_at | dateFormat("dd") }}
                    </p>
                    <p class="text-subtitle-1 font-weight-bold text-center">
                      {{ observacao.created_at | dateFormat("MMM") }}
                    </p>
                  </span>
                </template>
                <span>
                  {{ observacao.created_at | dateFormat("dd/MM/yyyy HH:mm") }}
                </span>
              </v-tooltip>
            </template>

            <v-card
              @click="goToClienteObs(observacao)"
              hover
              outlined
              class="pa-6 lightgray"
            >
              <p class="text-subtitle-1 mb-0 mb-sm-2 font-weight-bold">
                {{ observacao.user.name }}
              </p>
              <p class="text-caption mt-0 d-flex d-sm-none font-weight-bold">
                {{ observacao.created_at | dateFormat("dd/MM/yyyy HH:mm") }}
              </p>
              {{ observacao.obs }}
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchClientesObs } from "@/api/clientes/clientes_obs.js";
// import {}
export default {
  name: "ClienteObsList",

  components: {
    DialogClienteObs: () => import("./DialogClienteObs.vue"),
  },

  data() {
    return {
      cliente_observacao: {},
      dialogClienteObs: false,
      loading: false,
      cliente_observacoes: [],
      cliente_observacoes_original: {},
      search: "",
    };
  },

  props: {
    clienteId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.data"),
          value: "created_at",
          width: "15%",
        },

        {
          text: this.$tc("global.anotacao"),
          value: "obs",
        },
      ];
    },

    cliente_id() {
      if (this.clienteId) {
        return this.clienteId;
      }
      return this.$route.params.id;
    },
  },

  methods: {
    addClienteObs() {
      this.dialogClienteObs = true;
      this.edit = false;
    },

    goToClienteObs(item) {
      this.cliente_observacao = { ...item };
      this.dialogClienteObs = true;
      this.edit = true;
    },

    async getClientesObs() {
      this.loading = true;

      this.cliente_observacoes.cliente_id = await fetchClientesObs(
        `?cliente_id=${this.cliente_id}`
      )
        .then((response) => {
          this.cliente_observacoes = response;
          this.cliente_observacoes_original = { ...response };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getClientesObs();
  },
};
</script>

<style lang="scss" scoped>
// .v-timeline .v-timeline-item .v-timeline-item__body {
//   max-width: calc(90% - 48px) !important;
// }
::v-deep .v-timeline-item__body {
  flex: none !important;
  max-width: calc(95% - 48px) !important;
  width: calc(95% - 48px) !important;
  // @media (max-width: 420px) {
  //   width: 60% !important;
  //   max-width: 60% !important;
  // }
}
// ::v-deep .v-timeline-item__divider {
//   @media (max-width: 420px) {
//     min-height: 50px;
//   }
// }

// .v-timeline {
//   max-width: 100%;
//   flex: none;
//   .v-timeline-item {

//     .v-timeline-item__opposite {
//       max-width: 100px !important;
//     }
//   }
// }
</style>
